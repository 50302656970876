.reidars-stats-container {
    margin-bottom: clamp(1.5rem, calc(1.5rem + 48*((100vw - 600px) / 840)), 4.5rem);
    margin-top: clamp(1.5rem, calc(1.5rem + 48*((100vw - 600px) / 840)), 4.5rem);
    max-width: 90rem;
}

.reidars-table-wrapper {
    overflow-x: auto;
    max-width: 100%;
}

.reidars-datatable {
    background: #fff;
    border: 1px solid #D4D6D9;
    border-radius: .5rem .5rem 0 0;
    margin-bottom: 2rem;
    min-width: 100%;
    white-space: nowrap;
}

.reidars-datatable thead {
    background: #002fa3;
    color: #fff;
    text-transform: uppercase;
}

.reidars-datatable thead th:first-child {
    border-radius: .5rem 0 0 0;
    text-align: left;
}

.reidars-datatable thead th:last-child {
    border-radius: 0 .5rem 0 0;
}

.reidars-datatable thead th {
    text-align: right;
}

.reidars-datatable thead th,
.reidars-datatable tfoot th {
    padding: .5rem 1rem;
}

.reidars-datatable tbody tr:nth-child(even) {
    background: #f0f0f0;
}

.reidars-datatable tbody td,
.reidars-datatable tbody th,
.reidars-datatable tfoot td {
    padding: .5rem 1rem;
}

.reidard-datatable tbody a {
    color: #004d9a;
}

.reidars-datatable tbody th,
.reidars-datatable tfoot th {
    text-align: left;
}

.reidars-datatable tbody td,
.reidars-datatable tfoot td {
    text-align: right;
}

.reidars-datatable tfoot th,
.reidars-datatable tfoot td {
    border-top: 1px solid #D4D6D9;
}

.reidars-datatable-td-left {
    text-align: left !important;
}

.reidars-datatable-td-center {
    text-align: center !important;
}

.reidars-datatable-td-right {
    text-align: right !important;
}

.reidars-table-legend {
    font-size: .85rem !important;
    line-height: 1.25em !important;
    margin: 2em 0 !important;
}

.reidars-table-legend-span {
    display: inline-block;
    margin: 0 .5rem .5rem 0;
}

.reidars-backbutton {
    margin-bottom: 1rem;
}